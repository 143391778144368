import React, { useEffect } from "react"
import MainButton from "./main-button"

const CalendarEmbed = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.innerHTML = `
      (function (C, A, L) { let p = function (a, ar) { a.q.push(ar); }; let d = C.document; C.Cal = C.Cal || function () { let cal = C.Cal; let ar = arguments; if (!cal.loaded) { cal.ns = {}; cal.q = cal.q || []; d.head.appendChild(d.createElement("script")).src = A; cal.loaded = true; } if (ar[0] === L) { const api = function () { p(api, arguments); }; const namespace = ar[1]; api.q = api.q || []; if(typeof namespace === "string"){cal.ns[namespace] = cal.ns[namespace] || api;p(cal.ns[namespace], ar);p(cal, ["initNamespace", namespace]);} else p(cal, ar); return;} p(cal, ar); }; })(window, "https://app.cal.com/embed/embed.js", "init");
      Cal("init", {origin:"https://cal.com"});
      Cal("ui", {"theme":"light","styles":{"branding":{"brandColor":"#C79836"}},"hideEventTypeDetails":false,"layout":"column_view","month":"2024-08","date":"2024-08-10"});
    `
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <MainButton
      data-cal-namespace=""
      data-cal-link="smileinn/the-invisalign-day"
      data-cal-config='{"layout":"column_view","month":"2024-08","date":"2024-08-10"}'
      style={{ width: "100%", maxWidth: 350, margin: "0 auto" }}
    >
      Sign up now
    </MainButton>
  )
}

export default CalendarEmbed
