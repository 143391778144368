import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import CalendarEmbed from "../components/calendar-embed"
import MainButton from "../components/main-button"

export default function BrandNewSmileInn() {
  return (
    <Layout noHeader>
      <SEO
        title="The Invisalign Day 2024"
        description="Smile Inn is Emerald Certified in Invisalign Technology and is the first to feature the iTero 5D Scanner - allowing you to see your future smile in minutes!"
      />
      <MainContainer>
        <h1 style={{ textAlign: "center" }}>The Invisalign Day</h1>
        <p>
          National Align your Teeth Day is celebrated in North America every
          year on August 10th. Each year, Smile Inn Dental joins in on the
          celebration by hosting an event for any potential persons interested
          in Invisalign.
        </p>
        <p>
        Anyone who signs up for Invisalign on August 10th will benefit from
        special VIP pricing for one day only.
        </p>
        {/* <p>
          Thank you for your interest in our Annual Invisalign Day! All spots
          for August 10th have been filled, but we would love to add you to our
          waiting list. Please fill out the form below, and we will reach out if
          a spot opens up.
        </p> */}
        {/* <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeMFMK5SjeTLKmbccBMywJfTQfm3AJyVLeDPGk9mpPeKtYpDA/viewform?usp=pp_url"
          target="_blank"
          rel="noopener noreferrer"
          // style={{ display: "inline-block", width: "100%" }}
          style={{
            display: "inline-block",
            width: "100%",
            maxWidth: 350,
            margin: "0 auto",
          }}
        >
          <MainButton
            style={{ width: "100%", maxWidth: 350, margin: "0 auto" }}
          >
            Join the Waiting List
          </MainButton>
        </a> */}
        <CalendarEmbed />
      </MainContainer>
    </Layout>
  )
}

const MainContainer = styled.div`
  display: flex;
  padding: 22vh 20px;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 700px) {
    padding: 7vh 20px;
  }
`
